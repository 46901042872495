<template>
    <div class="home">
        <div class="date_box">
            <div class="one">
                <img class="photo" v-lazy="data.photo" alt="">
             
                <div>
                    <div class="name">{{ data.dispname }}</div>
                    <div class="fee_box">费用<span class="fee">￥{{ data.fee }}</span></div>
                </div>
            </div>


            <div class="bg_box">
                
                <div class="info_item">
                    <div>患者姓名：</div><span>{{ data.name }}</span>
                </div>
                <div class="info_item">
                    <div>预约类型：</div><span>{{ data.apptypename }}</span>
                </div>
                <div class="info_item">
                    <div>就诊诊所：</div><span>{{ data.clinicname }}</span>
                </div>
                <div class="info_item">
                    <div>预约时间：</div><span>{{ data.appointmenttime }}</span>
                </div>
                <div class="info_item" @click="getWxConfig()">
                    <div>就诊地点：</div><span>{{ data.address }}<br>电话：{{ data.phone }}</span><i
                        class="icon intro iconfont iconsanjiaoyou"></i>
                </div>
                <div class="info_item" v-if="data.warningimg||(data.warningcontent&& !(/^ *$/.test(data.warningcontent)))" @click="show_info = true">
                    <div>交通指引：</div><span class="info_l">点击查看医馆交通指引</span><i
                        class="icon  info_l iconfont iconsanjiaoyou"></i>
                </div>
            </div>


            <div class="bg_box">
                <div class="info_item">病情</div>
                <van-field v-model="data.complain" rows="5" autosize label="" type="textarea" maxlength="1000"
                    placeholder="请输入病情" show-word-limit />
                <!-- <div class="flex edit">
                    <van-icon name="edit" />修改病情
                </div> -->
            </div>
            <div class="bg_box" v-if="data.apptype==0">
                <div class="info_item">如有需要，请上传病历患病处</div>
                <div class="intro ">提示：可上传病历照片或患病处症状</div>
                <div class="img_box">
                    <van-uploader v-model="fileList" :after-read="afterRead" accept="image/*" :max-size="20 * 1024 * 1024"   preview-size="2.58rem"
                        @oversize="onOversize" lazy-load :before-delete="beforeDelete" />
                </div>
                <div class="exp"><span class="info_item" style="color:#CF8C57" @click="show_ex=true">查看示例<i class="icon  info_l iconfont iconsanjiaoyou"></i></span></div>
           
            </div>
            <div class="flex">
                <van-button round block @click="sub()" :loading="loading_bt">提交</van-button>
            </div>

        </div>

       
        <van-dialog v-model="show" title="选择患者">

        </van-dialog>
        <van-dialog v-model="show_info" confirm-button-text="关闭" title="就诊交通指引">
            <div class="dialog_box">
                <img :src="data.warningimg" />
                <h3 v-if="data.warningcontent&& !(/^ *$/.test(data.warningcontent))">地址指引：</h3>
                <div class="intro" style=" line-height: 25px;">{{ data.warningcontent }}</div>
            </div>

        </van-dialog>
        <van-dialog v-model="show_ex" confirm-button-text="关闭" title="示例">
            <div class="dialog_box">
                <img src="@/assets/medicalRecordExample.png" alt="" />
               
            </div>

        </van-dialog>
       
    </div>
</template>
<script>
import Vue from 'vue';
import { wxappointmentdetail, wxjsapiauth, wxsubmitappointmentinfo, wxattachqry,wxdeleteattach } from '@/api/medical'
import { mapGetters } from 'vuex'
import axios from 'axios'

import wx from 'weixin-js-sdk'
import { Toast,Lazyload } from 'vant';
import errorImg from '@/assets/医生头像.png'
Vue.use(Lazyload, {
        lazyComponent: true,
        loading: "https://yz.iyzzy.com/icon/loading.gif",
        error: errorImg
    });
export default {

    computed: {
        ...mapGetters([
            'username', 'clinicid', 'openid'
        ])
    },
    data() {
        return {
            data: {},
            planid: 0,
            show: false,
            customername: '',
            customerid: '',
            userid: '',
            show_info: false,
            loading_bt: false,
            fileList: [],
            sub_fileList: [],
            uploadUrl:process.env.VUE_APP_UPLOAD_URL,
            show_ex:false
    
        }
    },
    created() {
        this.$store.dispatch('hideOrShowNav', false)
        this.recordid = this.$route.query.recordid || ''
        this.getPlan()
    },

    methods: {
        getPlan() {
            let data = { username: this.username, recordid: this.recordid }
            wxappointmentdetail(data).then(response =>{
            this.data = response.response_body
            if(this.data.attachlist){
                this.fileList = this.data.attachlist.map((item)=>{
                return {url:item.url, isImage: true ,attachId :item.attchid }
            })
            }
            
            })
        },
        
        getWxConfig() {
            //注入权限验证
            let data = { url: window.location.href, openid: this.openid };
            wxjsapiauth(data).then(response => {

                let jsApiList = [
                'openLocation'
                ]
                wx.config({
                    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                    appId: response.response_body.appid, // 必填，公众号的唯一标识
                    timestamp: response.response_body.timestamp, // 必填，生成签名的时间戳
                    nonceStr: response.response_body.noncestr, // 必填，生成签名的随机串
                    signature: response.response_body.signature, // 必填，签名
                    url: response.response_body.url,
                    jsApiList: jsApiList // 必填，需要使用的JS接口列表
                });
                let latitude = this.data.latitude
                let longitude = this.data.longitude
                let clinicname = this.clinicname
                let address = this.data.address
                wx.showOptionMenu();
                wx.ready(function () {
                    console.log('监听，打开微信地图')
                    //监听，打开微信地图
                    wx.openLocation({
                        latitude: parseFloat(latitude || 0), // 纬度，浮点数，范围为90 ~ -90
                        longitude: parseFloat(longitude || 0), // 经度，浮点数，范围为180 ~ -180。
                        name: clinicname, // 位置名
                        scale: 15,// 地图缩放级别,整形值,范围从1~28。默认为最大
                        address: address, // 地址详情说明
                        // infoUrl: 'https://www.baidu.com',
                      
                    });

                });
            })
        },

        afterRead(file, detail) {
            file.status = 'uploading';
            file.message = '上传中...';
            let formData = new FormData()
            formData.append('file', file.file)


            // formData.append('docNum', this.fileType)
            // formData.append('docId', this.docId)
            const instance = axios.create({
                withCredentials: true
            })
            let config = {
                headers: {
                    'Content-Type': 'application/octet-stream',
                }
            }
          
            let url = this.uploadUrl + "?docNum=" + '1002' + "&userId=" +this.data.userid + "&docId=" + this.data.docid
           
            instance.post(url, formData, config).then((response) => {
                console.log(response, '---upload response')
                if (response.data) {
                    if (response.data.error == '0') {
                        file.attachId = response.data.attchId
                        file.status = 'done';
                        Toast.success('图片上传成功');
                    } else {
                        Toast.fail('图片上传异常');
                        this.fileList.splice(detail.index, 1)
                    }
                } else {
                    Toast.fail('图片上传异常');
                    this.fileList.splice(detail.index, 1)
                }

            }).catch(() => {
                Toast.fail('图片上传失败');
                this.fileList.splice(detail.index, 1)
            })

        },
        onOversize() {
            Toast.fail('图片大小不能超过20M');
        },
        beforeDelete(file, detail) {
            let vm = this;
            this.$dialog.confirm({
                title: null,
                message: "是否删除该图片",
            })
                .then(() => {
                    return new Promise((resolve, reject) => {
                        Toast.loading({
                            message: '正在删除图片...',
                            forbidClick: true,
                        })
                        let data = {
                            username: this.username,
                            attachid: file.attachId
                        }
                        wxdeleteattach(data).then(() => {
                            Toast.clear()
                            console.log(detail.index);
                            vm.fileList.splice(detail.index, 1)
                            Toast.success('图片删除成功');
                        })
                        resolve()
                    })
                })
                .catch(() => {
                    Toast.success('取消图片删除');
                    return false
                });
        },
        // 获取附件组
        async getAttachList() {
            Toast.loading({
                message: '加载中...',
                forbidClick: true,
            })
            let data = {
                username: this.username,
                docid: this.data.docid
            }
            await wxattachqry(data).then(response => {
                Toast.clear()
                debugger;
                if (response.response_body.num > 0) {
                   
                    this.sub_fileList=response.response_body.list.map((item)=>{
                        return {attchid:item.attachid,url:item.url}
                    })
                    
                }
            })
        },
        async sub() {
            this.loading_bt = true
            // this.fileList.forEach((item)=>{
            //     return {attchid:item,url:this.uploadUrl}
            // })

            await this.getAttachList();
            
            
            let sub_data = {
                username: this.username,
                recordid: this.recordid,
                complain: this.data.complain,
                attachlist: this.sub_fileList,
                // attchid:
                // url
            }
            wxsubmitappointmentinfo(sub_data).then(response => {

            }).then(() => {
                this.loading_bt = false
                Toast.success('提交成功');
                setTimeout(()=>   
                 this.$router.go(-1)
                 , 2000)
            
            }).catch(() => {
                    this.loading_bt = false
                    Toast.success('提交失败'); 
                });
        }

    }
}

</script>
<style lang="scss" scoped>
.home {
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    background: rgb(246, 246, 244);
    height: 100vh;
    overflow:auto;
    .one {
        display: flex;
    }

    .name {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }

    .tab_box {
        display: flex;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        overflow: hidden;
        width: 100%;
        margin-top: 10px;
    }

    .tab_item {
        flex: 1;
        font-size: 18px;
        font-weight: 600;
        height: 40px;
        line-height: 40px;
        text-align: center;
        background-color: #c2c0b9;
        color: #fff;
    }

    .fee {
        width: 100px;
        text-align: end;
        color: rgb(207, 140, 87);
    }

    .photo {
        width: 2rem;
        height: 2.5rem;
        border-radius: 10px;
        margin-right: 10px;
    }

    .bg_box {
        background-color: #fff;
        border-radius: 10px;
        padding: 5px 15px;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .iconsanjiaoyou {
        font-size: 26px;
        width: 15px;
    }

    .intro {
        color: #999995 !important;
        word-break:break-all
    }

    .info_item {
        display: flex;
        color: #333;
        line-height: 24px;
        padding: 5px 0px;

        div {
            min-width: 80px;
        }

        span {
            color: #999995;
        }
    }

    .iconsanjiaoyou {
        font-size: 26px;
        width: 15px;
    }

    .info_l {
        color: rgb(207, 140, 87) !important;
    }

    h3 {
        margin-top: 10px;
        margin-bottom: 5px;
    }

    .cell_box {
        margin-bottom: 15px;
    }

    .dialog_box {
        padding: 10px 15px;

        img {
            width: 100%;
        }
    }


    :deep .van-dialog__content {
        max-height: 450px;
        overflow: auto;
    }

    :deep .van-dialog {
        width: 90%;
    }

    :deep .left {
        width: unset !important;
        display: flex;
    }

    :deep .right {
        width: unset !important;
    }

    :deep .treat_main_list {
        width: 100%;

        .van-radio {
            overflow: visible;

        }
    }

    :deep .van-radio {
        display: flex;
    }

    :deep .btnimg {
        display: none;
    }

    :deep .detail_info {
        display: none !important;
    }

    :deep .van-dialog__header {
        padding: 10px 0px !important;
    }

    :deep .van-cell {
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    :deep .van-icon {
        height: unset !important;
    }

    :deep .van-radio__icon {
        height: 1.1rem;
    }

    :deep .van-button--round {
        background-color: #CF8C57;
        width: 150px;
        height: 38px;
        text-align: center;
        line-height: 38px;
        color: #fff;
        border-radius: 21px;
    }

    .flex {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    :deep .van-cell::after {
        border: unset;
    }

    .edit {
        height: 1rem;
        color: #CF8C57;
    }

    .img_box {
        margin-top: 10px;
    }
    .fee_box{
        margin-top: 1rem;
    }
    .exp{
        line-height: 42px;
        display: flex;
        justify-content: end;
        color: #CF8C57;
    }
}
</style>